<template>
    <main>
        <NuxtLayout name="admin">
            <template #header>
                <button
                    v-for="i in totalPages + 1"
                    @click="loadPage(i - 1)"
                    class="px-1"
                    :class="currentIndex === i - 1 ? 'bg-vann text-white' : ''"
                >
                    {{ i - 1 }}
                </button>
            </template>
            <div v-if="socket && currentPage && !isMob" class="@container">
                <component
                    :is="currentPage"
                    :socket="socket"
                    :uuid="uuid"
                    :step-name="stepName"
                    @next="loadPage(currentIndex + 1)"
                    @prev="loadPage(currentIndex - 1)"
                />
            </div>
            <div
                v-if="!currentPage"
                class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2"
            >
                <svg-icon name="loader" class="animate-spin w-20 h-20" />
            </div>
            <div v-if="currentPage && isMob && isAdmin" class="md:hidden fixed inset-0 bg-natt p-4">
                <Remote
                    :step-name="stepName"
                    :step-num="currentIndex"
                    :socket="socket"
                    :uuid="uuid"
                    :videoStatus="videoStatus"
                />
            </div>
            <section
                v-if="isAdmin"
                class="bg-nordlys-30 md:pt-6 md:bg-white fixed inset-x-0 bottom-0 flex items-center justify-between text-svart px-6 py-4"
            >
                <div class="items-center gap-6 hidden md:flex">
                    <button type="button" @click="showQR = true">
                        <qrcode-vue :value="romUrl()" :size="32" level="M" />
                    </button>
                    <strong class="text-4 text-natt">{{ route.params.code }}</strong>
                </div>
                <div
                    class="hidden bg-white px-8 md:flex items-center transition-transform"
                    :class="readyPlayers === 0 && 'md:translate-y-[150%]'"
                >
                    <div class="flex-none">
                        <svg-icon
                            :name="ferdig ? 'ferdig' : 'loader'"
                            :class="[!ferdig && 'animate-spin', 'h-6 w-6 my-2']"
                        />
                    </div>
                    <div class="text-fjell ml-4 mr-8">
                        <strong>{{ readyPlayers }}</strong> av {{ connectedPlayers }}
                        {{ t('stykk er klare') }}
                    </div>
                    <div class="md:w-[300px] h-2 shadow-sm rounded bg-nordlys-30">
                        <div
                            class="h-2 rounded bg-nordlys min-w-[5px]"
                            :style="`width:${(readyPlayers / connectedPlayers) * 100}%`"
                        ></div>
                    </div>
                </div>
                <button
                    v-if="currentIndex >= 1 && currentIndex < 17"
                    type="button"
                    class="bg-white w-12 h-12 pr-1 rounded-full ml-auto md:bg-sand md:w-auto md:flex md:items-center md:px-4"
                    @click="prevPage()"
                >
                    <svg-icon name="chevron" class="w-5 h-5 mx-auto rotate-90" />
                    <span class="hidden md:block">{{ t('Forrige') }}</span>
                </button>
                <button
                    v-if="currentIndex < 17"
                    type="button"
                    class="btn bg-nordlys text-white flex items-center ml-4 disabled:opacity-50"
                    :disabled="timelineWait && stepName === 'c'"
                    @click="nextPage()"
                >
                    <svg-icon
                        v-if="videoPages.includes(currentIndex) && videoStatus !== 'end'"
                        :name="videoStatus === 'playing' ? 'pause' : 'play'"
                        class="w-6 h-6 mr-2"
                    />
                    <span>{{ nextText }}</span>
                    <svg-icon
                        v-if="!videoPages.includes(currentIndex) || videoStatus === 'end'"
                        name="chevron"
                        class="w-5 h-5 -rotate-90 ml-3 mt-px"
                    />
                </button>
            </section>
            <section
                v-if="showQR"
                class="fixed bg-svart/50 z-10 inset-0 transition-opacity flex items-center justify-center"
            >
                <div
                    class="bg-white shadow p-4 rounded-lg text-natt text-center flex flex-col max-w-[90%] max-h-[90%]"
                >
                    <qrcode-vue :value="romUrl()" :size="500" class="!w-full !h-auto" level="M" />
                    <div class="text-8 my-4">
                        <div>{{ t('Romnummer') }}</div>
                        <strong class="text-10">{{ route.params.code }}</strong>
                    </div>
                    <button
                        class="rounded-full shadow-hard bg-white whitespace-nowrap text-fjell flex items-center px-8 py-3 mx-auto"
                        @click="toggleQr"
                    >
                        <svg-icon name="pluss" class="w-5 h-5 rotate-45 mr-2" />
                        {{ t('Lukk') }}
                    </button>
                </div>
            </section>
            <button
                class="hidden md:flex absolute top-10 right-10 btn bg-white text-fjell items-center"
                @click="toggle()"
            >
                <svg-icon
                    :name="isFullscreen ? 'fullskjerm_lukk' : 'fullskjerm'"
                    class="size-6 mr-2"
                />
                {{ isFullscreen ? t('Stopp fullskjerm') : t('Fullskjerm') }}
            </button>
        </NuxtLayout>
        <ClientOnly v-if="$config.public.env != 'production'">
            <div
                :class="[
                    isActive ? 'bg-skog-70' : 'bg-fjell',
                    'text-center absolute let-0 top-0 z-10 text-white p-1'
                ]"
            >
                {{ isActive ? 'wake aktiv' : 'wake ikke aktiv' }}
            </div>
        </ClientOnly>
        <div v-if="!isAdmin" class="md:hidden">
            <div class="fixed flex inset-0 bg-fjell bg-opacity-80">
                <div class="my-auto w-full p-10 mx-4 bg-frost-30 text-svart rounded-xl">
                    <span class="text-[100px] flex">
                        <NuxtImg
                            src="/img/fjernkontroll.png"
                            width="350"
                            format="webp"
                            class="relative rounded-md w-full"
                        />
                        {{ uuid.substring(0, 3).toUpperCase() }}
                    </span>
                    <p class="mt-4">
                        {{ t('Velg telefon med kode') }}
                        {{ uuid.substring(0, 3).toUpperCase() }}
                        {{ t('på storskjermen for å knytte sammen mobilen med storskjermen.') }}
                    </p>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
import platform from 'platform';
import QrcodeVue from 'qrcode.vue';

import { useFullscreen, onKeyStroke, useWakeLock } from '@vueuse/core';
const { isFullscreen, toggle } = useFullscreen();
const { request, isActive } = useWakeLock();
const route = useRoute();
definePageMeta({
    layout: false
});
useSeoMeta({
    title: `Rom ${route.params.code}`
});
const { t } = useI18n();
const userStore = useUserStore();
const ctx = useNuxtApp();
let socket: any;
const totalPages = 15;
const currentPage = shallowRef('');
const currentIndex = ref(0);
const connectedPlayers = ref(0);
const readyPlayers = ref(0);
const showQR = ref(false);
const uuid = ref('');
const isAdmin = ref(false);
const videoPages = [3, 5, 10];
const timelinePages = [7, 12];
const stepName = ref('a');
const isMob = ref(false);
const videoStatus = ref('end');
const timelineWait = ref(false);

onMounted(() => {
    isMob.value = window.innerWidth <= 600;
    uuid.value = userStore.id;
    stepName.value = localStorage.getItem(`stepname-${route.params.code}`) || 'a';
    socket = ctx.$nuxtSocket({ path: '/ws/socket.io' });
    socket.emit('join-room', route.params.code);
    socket.emit('join-room-admin', route.params.code);
    socket.emit('check-admin', { uuid: uuid.value });
    socket.on('check-admin', (check: boolean) => {
        isAdmin.value = check;
        // isAdmin.value = true;
        // if (!check) {
        //     location.href = '/';
        // }
        if (!check) {
            socket.emit('remote-id', {
                uuid: uuid.value,
                phone: platform.product || platform.name
            });
        }
    });
    socket.on('navigate', ({ num, type }: { num: string; type: string }): void => {
        if (type === 'admin') {
            const step = parseInt(num.split(' ')[0]);
            const name = num.split(' ')[1];
            if (currentIndex.value === step && stepName.value === name) {
                return;
            }
            currentIndex.value = step;
            stepName.value = name;
            localStorage.setItem(`stepname-${route.params.code}`, stepName.value);
            loadPage(currentIndex.value, true);
        }
    });
    socket.on('new-room', async (msg: string) => {
        await navigateTo(`/rom/${msg}/admin`);
    });
    socket.on('load-room', (num: number) => {
        loadPage(num, true);
    });
    socket.on('clients', (msg: string) => {
        connectedPlayers.value = parseInt(msg);
        if (stepName.value === 'a') {
            socket.emit('lock', { uuid: uuid.value });
        }
    });
    socket.on('player-ready', async (msg: string) => {
        readyPlayers.value = parseInt(msg);
        if (!isActive.value) {
            try {
                await request('screen');
            } catch (e) {
                // console.log('admin', e);
            }
        }
    });
    socket.on('start video', () => {
        videoStatus.value = 'playing';
    });
    socket.on('pause video', () => {
        videoStatus.value = videoStatus.value === 'playing' ? 'pause' : 'playing';
    });
    socket.on('restart video', () => {
        videoStatus.value = 'playing';
    });
    socket.on('video end', () => {
        videoStatus.value = 'end';
    });
    socket.on('select-remote', ({ uuid: newId, selected }: { uuid: string; selected: string }) => {
        if (selected === uuid.value) {
            uuid.value = newId;
            localStorage.setItem('uuid', uuid.value);
            socket.emit('check-admin', { uuid: uuid.value });
        }
    });
});

watch(currentIndex, val => {
    loadPage(val);
});

const nextText = computed(() => {
    if (currentIndex.value === 0) {
        return t('Start');
    } else if (currentIndex.value === 15 && stepName.value === 'c') {
        return t('Avslutt');
    } else if (currentIndex.value === 16) {
        return t('Send inn tilbakemelding?');
    } else if (stepName.value === 'a' && videoPages.includes(currentIndex.value)) {
        if (videoStatus.value === 'none') {
            return t('Start video');
        } else if (videoStatus.value === 'playing') {
            return t('Pause');
        } else if (videoStatus.value === 'pause') {
            return t('Play');
        } else {
            return t('Neste tema');
        }
    } else if (stepName.value === 'c' || (currentIndex.value === 14 && stepName.value === 'b')) {
        return t('Neste tema');
    } else if (stepName.value === 'a' && timelinePages.includes(currentIndex.value)) {
        const year = new Date();
        year.setFullYear(year.getFullYear() + 1);
        if (currentIndex.value > 10) {
            year.setFullYear(year.getFullYear() + 1);
        }
        return t('Gå til') + ' ' + year.getFullYear();
    } else if (stepName.value === 'b') {
        return t('Vis statistikk');
    } else if (stepName.value === 'a') {
        return t('Start oppgave');
    }
});

const ferdig = computed(() => {
    return connectedPlayers.value > 0 && readyPlayers.value === connectedPlayers.value;
});

const toggleQr = () => {
    showQR.value = !showQR.value;
};

onKeyStroke('ArrowLeft', () => {
    prevPage();
});

onKeyStroke('ArrowRight', () => {
    nextPage();
});

const nextPage = () => {
    if (currentIndex.value === 0) {
        loadPage(1);
        socket.emit('navigate', { num: 1, uuid: uuid.value, type: 'client' });
        localStorage.setItem(`stepname-${route.params.code}`, 'a');
        return;
    } else if (
        stepName.value === 'a' &&
        videoPages.includes(currentIndex.value) &&
        videoStatus.value !== 'end'
    ) {
        if (videoStatus.value === 'playing') {
            socket.emit('pause video', { uuid: uuid.value });
        } else {
            socket.emit('start video', { uuid: uuid.value });
        }
        return;
    } else if (
        stepName.value === 'a' &&
        !videoPages.includes(currentIndex.value) &&
        currentIndex.value <= 15
    ) {
        stepName.value = 'b';
        localStorage.setItem(`stepname-${route.params.code}`, 'b');

        socket.emit('navigate', {
            num: currentIndex.value + ' b',
            uuid: uuid.value,
            type: 'admin'
        });
        socket.emit('unlock', { uuid: uuid.value });

        if (timelinePages.includes(currentIndex.value)) {
            timelineWait.value = true;
            setTimeout(() => {
                timelineWait.value = false;
            }, 10000);
        }
        return;
    } else if (stepName.value === 'b' && currentIndex.value !== 14) {
        stepName.value = 'c';
        localStorage.setItem(`stepname-${route.params.code}`, 'c');
        socket.emit('navigate', {
            num: currentIndex.value + ' c',
            uuid: uuid.value,
            type: 'admin'
        });
        socket.emit('unlock', { uuid: uuid.value });
        return;
    }
    videoStatus.value = 'none';
    stepName.value = 'a';
    localStorage.setItem(`stepname-${route.params.code}`, 'a');
    socket.emit('navigate', { num: currentIndex.value + 1, uuid: uuid.value, type: 'client' });
    // if (currentIndex.value + 1 === 16) {
    //     socket.emit('navigate', { num: 16, uuid, type: 'client' });
    // }
    loadPage(currentIndex.value + 1);
};

const prevPage = () => {
    if (currentIndex.value < 1) {
        return;
    }
    if (stepName.value === 'c') {
        socket.emit('navigate', {
            num: currentIndex.value + ' b',
            uuid: uuid.value,
            type: 'admin'
        });
    } else if (stepName.value === 'b') {
        socket.emit('navigate', {
            num: currentIndex.value + ' a',
            uuid: uuid.value,
            type: 'admin'
        });
    } else if (currentIndex.value > 1) {
        socket.emit('navigate', {
            num: currentIndex.value - 1 + ' a',
            uuid: uuid.value,
            type: 'admin'
        });
        socket.emit('navigate', { num: currentIndex.value - 1, uuid: uuid.value, type: 'client' });
    }
};

const loadPage = (num: number, trigger: boolean = false) => {
    if (isNaN(num) || num === null) {
        navigateTo('/rom');
        return;
    }
    if (num >= 16) {
        //TODO: Vis noe avsluttingsgreier
    }
    if (socket && !trigger) {
        socket.emit('navigate', {
            num: num + ' ' + stepName.value,
            uuid: uuid.value,
            type: 'admin'
        });
        if (stepName.value === 'a') {
            socket.emit('lock', { uuid: uuid.value });
        }
    }
    currentIndex.value = num;
    import(`../../../components/admin-pages/page-${num}.vue`).then(res => {
        //@ts-ignore
        if (currentPage.value?.__name != res.default.__name) {
            currentPage.value = res.default;
        }
    });
};
</script>
