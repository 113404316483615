<template>
    <div class="relative h-screen bg-natt text-white w-full p-4">
        <div class="h-12 flex items-center w-2/3 mx-auto">
            <span>1</span>
            <div class="bg-white rounded-full mx-4 h-2 flex-grow">
                <div
                    class="bg-frost rounded-full h-full relative transition-all duration-500"
                    :style="`width:${(stepNum / 16) * 100}%`"
                >
                    <div
                        v-if="stepNum > 0"
                        class="w-6 h-6 leading-6 text-center rounded-full bg-frost absolute top-0 right-0 -mr-3 -mt-2"
                    >
                        {{ stepNum }}
                    </div>
                    <div
                        class="w-4 h-4 bg-vann-70 rotate-45 absolute top-full mt-4 right-0 -mr-2"
                    ></div>
                </div>
            </div>
            <span>16</span>
        </div>
        <ContentRenderer :value="pages || []">
            <template v-for="id in pages">
                <div
                    class="relative text-white flex flex-col justify-center transition-all duration-500"
                    :class="
                        id === active
                            ? 'bg-vann-70 p-1.5 max-h-96 mb-20 w-2/3 mx-auto rounded-xl'
                            : id < active
                            ? 'bg-vann-70 max-h-0 w-2/3 mx-auto overflow-hidden'
                            : 'css--next-slide max-h-96 bg-vann mb-6 w-full rounded-md overflow-hidden delay-200'
                    "
                >
                    <NuxtImg
                        :src="`/img/screens/${id}.${
                            ['03a', '05a', '10a'].includes(id) ? 'jpg' : 'png'
                        }`"
                        width="350"
                        format="webp"
                        class="relative rounded-md w-full"
                    />

                    <div
                        v-if="id === active && ['03a', '05a', '10a'].includes(id)"
                        class="absolute inset-0 flex flex-col justify-around items-center py-2"
                    >
                        <button
                            class="btn bg-fjell flex items-center"
                            @click="socket.emit('restart video', { uuid })"
                        >
                            <svg-icon name="restart" class="w-6 h-6 mr-2" />
                            {{ t('Restart') }}
                        </button>
                    </div>
                </div>
                <span
                    class="block text-frost overflow-hidden transition-all duration-500"
                    :class="id === active && id !== '15b' ? 'max-h-12' : 'max-h-0'"
                >
                    {{ t('Neste skjerm') }}
                </span>
            </template>
        </ContentRenderer>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    stepName: {
        type: String,
        required: true
    },
    stepNum: {
        type: Number,
        required: true
    },
    socket: {
        type: Object as () => any,
        required: true
    },
    uuid: {
        type: String,
        required: true
    },
    videoStatus: {
        type: String,
        required: true
    }
});
const active = computed(() => {
    let n = props.stepNum < 10 ? '0' + props.stepNum : props.stepNum;

    return n + props.stepName;
});

const pages = ref<string[]>([]);
const { locale, t } = useI18n();

onNuxtReady(async () => {
    const { data } = await useAsyncData('remote-data', () =>
        queryContent(`${locale.value}/remote`).findOne()
    );
    //@ts-ignore
    pages.value = data.value?.body;
});
</script>

<style scoped>
.css--next-slide ~ * {
    max-height: 0;
}
</style>
